import React from 'react';
import { Tab, Grid, Header, List } from 'semantic-ui-react';

const CityServiceTab = () => {

    return (
        <Tab.Pane style={{ backgroundColor: '#6495ED' }}>
            <Grid>
                <Grid.Column width={16}>
                    <Header content='주일예배' />
                    <List>
                        <List.Item><b>교회 사무실 102/515 Kent St. Sydney</b></List.Item>
                        <List.Item>주일 1부 예배 오전 11시 30분</List.Item>
                        <List.Item>&nbsp;</List.Item>
                        <List.Item><b>Scots Presbyterian Church</b></List.Item>
                        <List.Item>주일 2부 예배 오후 2시 30분</List.Item>
                        <List.Item>주일 3부 예배 오후 6시 30분</List.Item>
                    </List>
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};

export default CityServiceTab;
