import React, { Fragment, useContext } from 'react';
import { Menu, Header } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { AllCategory, VideoCategories } from '../../../app/common/constants/categories';

const VideoFilters = () => {
    const rootStore = useContext(RootStoreContext);
    const { category, setCategory } = rootStore.videoStore;

    return (
        <Fragment>
            <Menu vertical size={'large'} style={{ width: '100%', marginTop: 5 }}>
                <Header icon={'filter'} attached color={'blue'} content={'Filters'} />
                <Menu.Item
                    active={category === AllCategory}
                    onClick={() => setCategory(AllCategory)}
                    color={'blue'}
                    name={AllCategory}
                    content={AllCategory} />
                <Menu.Item
                    active={category === VideoCategories.theHeartService}
                    onClick={() => setCategory(VideoCategories.theHeartService)}
                    color={'blue'}
                    name={VideoCategories.theHeartService}
                    content='목요 찬양 예배' />
                <Menu.Item
                    active={category === VideoCategories.event}
                    onClick={() => setCategory(VideoCategories.event)}
                    color={'blue'}
                    name={VideoCategories.event}
                    content='행사' />
                <Menu.Item
                    active={category === VideoCategories.praise}
                    onClick={() => setCategory(VideoCategories.praise)}
                    color={'blue'}
                    name={VideoCategories.praise}
                    content='찬양' />
            </Menu>
        </Fragment>
    );
};

export default observer(VideoFilters);
