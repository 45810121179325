export const AssociatePastors = [    
    {
        title: '성철 (City. 기대주 목장)', phone: '0408 979 256',
        email: 'sungchul430@gmail.com',
        picture: '/assets/images/pastors/sc.png'
    },
    {
        title: '강현규 (City. 대학 목장, 아동부)',
        phone: '0452 200 979', email: 'intil09@naver.com',
        picture: '/assets/images/pastors/khk.png'
    },
    {
        title: '이성구(West Ryde 협동, 새가족사역)',
        phone: '0413 246 458', email: 'world318@gmail.com',
        picture: '/assets/images/pastors/lsk.png'
    }
]


export const AssistantPastors = [
    {
        title: '김경미 (City 영아부, City한글학교)',
        phone: '0406 190 050', email: 'kyungmi1609@gmail.com',
        picture: 'assets/images/pastors/kkm.png'
    },
    {
        title: '이혜금 (West Ryde 영아부)',
        phone: '0414 937 637', email: 'haykay37@gmail.com',
        picture: '/assets/images/pastors/lhk.png'
    },
    {
        title: '이효선 (West Ryde 찬양대)',
        phone: '0452 510 179', email: 'lachelhs@hanmail.net',
        picture: '/assets/images/pastors/lhs.png'
    }, 
    {
        title: 'Michiko (일본인 사역)',
        phone: '0425 291 444', email: 'michiko.kaneko11@gmail.com',
        picture: '/assets/images/pastors/michiko.png'
    },
    {
      title: '남현수',
      phone: '0433 461 902', email: ' ',
      picture: '/assets/images/pastors/user.png'
    }
]

// {()
//     title: '박성현 (City. 목요찬양, West Ryde. 학생부)',
//     phone: '0435 941 842', email: 'ericpark1214@gmail.com',
//     picture: '/assets/images/pastors/psh.png'
// },
// {
//   title: '이재민',
//   phone: '0430 313 093', email: '',
//   picture: '/assets/images/pastors/user.png'
// }

// {
//   title: '박용진 (City 행정)', phone: '0433-034-205', email: 'joyful1229@hanmail.net',
//   picture: './assets/images/pastors/pyj.png'
// },
// {
//   title: '김진현 (West Ryde 행정, 어성경, 새가족)',
//   phone: '0401 963 868', email: 'jinhyunkim75@gmail.com',
//   picture: '/assets/images/pastors/kjh.png'
// },
// {
//   title: '김혜경 (City. 두드림 목장, 어성경, 목회행정팀)',
//   phone: '0430 369 659', email: 'foothyaf@hotmail.com',
//   picture: '/assets/images/pastors/khkyung.png'
// },

