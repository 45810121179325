import React, { useContext } from 'react';
import { Grid, Breadcrumb } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import PastorContent from './PastorContent';

const sections = [
    { key: 'CHURCH', content: '교회 소개', link: true },
    { key: 'Pastor', content: '교역자 소개', active: true },
];

const PastorPage = () => {
    const rootStore = useContext(RootStoreContext);
    const {
        setActiveTab
    } = rootStore.pastorStore;

    return (
        <Grid>
            <Grid.Column width={16}>
                <Breadcrumb icon='right angle' sections={sections}
                    style={{ marginTop: '20px', marginLeft: '20px', marginRight: '20px' }} />
            </Grid.Column>
            <Grid.Column width={16}>
                <PastorContent setActiveTab={setActiveTab} />
            </Grid.Column>
        </Grid >
    );
};

export default observer(PastorPage);