import React from 'react';
import { Card, Image } from 'semantic-ui-react';

interface IProps {
    title: string,
    phone: string,
    email: string,
    picture: any
}

const PastorCardItem: React.FC<IProps> = ({ title, phone, email, picture }) => {
    return (
        <Card style={{ width: '213px', marginLeft: 'auto', marginRight: 'auto' }}>
            <Image size='small' style={{ margin: '30px' }}
                src={picture} wrapped ui={false} />
            <Card.Content>
                <Card.Header>
                    {title}
                </Card.Header>
                <Card.Description>
                    {phone}<br />
                    {email}
                </Card.Description>
            </Card.Content>
        </Card >
    );
}

export default PastorCardItem;

// 