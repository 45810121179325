import React from 'react';
import { Tab, Card, Segment, Item } from 'semantic-ui-react';
import { AssociatePastors } from '../../../app/data/pastors';
import PastorCardItem from '../PastorCardItem';

interface IProps {
    isMobileView: boolean;
}

const AssociatePastorsTab: React.FC<IProps> = ({ isMobileView }) => {
    if (isMobileView) {
        return <Tab.Pane>
            {AssociatePastors.map(associatePastor =>
                <Segment key={associatePastor.email}>
                    <Item.Group>
                        <Item>
                            <Item.Image
                                size="small"
                                circular
                                src={associatePastor.picture}
                                style={{ marginBottom: 3 }}
                            />
                            <Item.Content>
                                <Item.Header>
                                    {associatePastor.title}
                                </Item.Header>
                                <Item.Description>
                                    {associatePastor.email} <br />
                                    {associatePastor.phone}<br />
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Segment>
            )}
        </Tab.Pane>
    }



    return (<Tab.Pane>
        <Card.Group>
            {AssociatePastors.map(associatePastor =>
                <PastorCardItem key={associatePastor.email} title={associatePastor.title} email={associatePastor.email}
                    picture={associatePastor.picture} phone={associatePastor.phone} />)
            }
        </Card.Group>
    </Tab.Pane>
    );
};

export default AssociatePastorsTab;