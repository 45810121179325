import React, { Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';
import * as Styles from '../styles'
import logo from '../../../assets/images/logo.png';

interface IProp {
    pathName:string,
    children:JSX.Element,
    menuItemClick: ()=>void;
}

const NoRoleMobile = ({pathName, menuItemClick, children}:IProp) => {
    return (
        <Fragment>
            <Menu.Item header as={NavLink} exact to="/" style={Styles.logoStyleMobile} onClick={menuItemClick} >
                <img src={logo} alt="logo" style={{ margin: '0px', width: '110px' }} />
            </Menu.Item>

            <Menu.Item name="West ryde" as={NavLink} to="/west-ryde" onClick={menuItemClick}
                style={pathName === '/west-ryde' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />

            <Menu.Item name="City" as={NavLink} to="/service-city" onClick={menuItemClick}
                style={pathName === '/service-city' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />

            <Menu.Item name="목요 찬양" as={NavLink} to="/service-the-heart" onClick={menuItemClick}
                style={pathName === '/service-the-heart' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />

            <Menu.Item name="주보"
                style={(pathName === '/westryde_bulletins' ||
                    pathName === '/city_bulletins') ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>
                <Dropdown pointing="top left" text='주보' style={{ width: '120px' }}>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/westryde_bulletins" text="West Ryde 주보" onClick={menuItemClick}
                            style={pathName === '/westryde_bulletins' ? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} />
                        <Dropdown.Item as={Link} to="/city_bulletins" text="City 주보" onClick={menuItemClick}
                            style={pathName === '/city_bulletins' ? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
            <Menu.Item name="교회 소개" style={(pathName === '/church' ||
                pathName === '/pastors' ||
                pathName === '/contacts') ? Styles.activeMenuItemStyle : Styles.menuItemStyle} >
                <Dropdown pointing="top left" text='교회 소개' style={{ width: '120px' }}>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/church" text="교회 소개" onClick={menuItemClick}
                            style={pathName === '/church' ? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} />
                        {/* <Dropdown.Item as={Link} to="/pastors" text="교역자 소개" onClick={menuItemClick}
                            style={pathName === '/pastors' ? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} /> */}
                        <Dropdown.Item as={Link} to="/contacts" text="Contacts" onClick={menuItemClick}
                            style={pathName === '/contacts' ? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
            <Menu.Item name="교회 영상" style={(pathName === '/video_event' ||
                pathName === '/video_praise') ? Styles.activeMenuItemStyle : Styles.menuItemStyle} >
                <Dropdown pointing="top left" text='교회 영상' style={{ width: '120px' }}>
                    <Dropdown.Menu>
                        <Dropdown.Item as={Link} to="/video_event" text="행사" onClick={menuItemClick}
                            style={pathName === '/video_event' ? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} />
                        <Dropdown.Item as={Link} to="/video_praise" text="찬양" onClick={menuItemClick}
                            style={pathName === '/video_praise' ? Styles.activeSubMenuItemStyle : Styles.menuItemStyle} />
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>
            <Menu.Item name="교회 앨범" as={NavLink} to="/galleriesMobile"
               style={pathName === '/galleriesMobile' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />
        {children}
    </Fragment>
    );
};

export default NoRoleMobile;