import React from 'react';
import { Tab } from 'semantic-ui-react';
import SeniorPastorTab from './tab/SeniorPastorTab';
import AssociatePastorsTab from './tab/AssociatePastorsTab';
import AssistantPastorsTab from './tab/AssistantPastorsTab';

interface IProps {
    setActiveTab: (activeIndex: any) => void;
};

const paneFontSize = '15px';
const panes = [
    { menuItem: { key: 'seniorPastor', content: '담임 목사', style: { fontSize: paneFontSize } }, render: () => <SeniorPastorTab /> },
    { menuItem: { key: 'AssociatePastors', content: '목사', style: { fontSize: paneFontSize } }, render: () => <AssociatePastorsTab isMobileView={false} /> },
    { menuItem: { key: 'AssistantPastorsTab', content: '전도사', style: { fontSize: paneFontSize } }, render: () => <AssistantPastorsTab isMobileView={false} /> },
];

const PastorContent: React.FC<IProps> = ({ setActiveTab }) => {
    return (
        <Tab
            panes={panes}
            onTabChange={(e, data) => setActiveTab(data.activeIndex)} />
    );
};

export default PastorContent;
