import React from 'react';
import { Tab, Card, Segment, Item } from 'semantic-ui-react';
import { AssistantPastors } from '../../../app/data/pastors';
import PastorCardItem from '../PastorCardItem';

interface IProps {
    isMobileView: boolean;
}

const AssistantPastorsTab: React.FC<IProps> = ({ isMobileView }) => {
    if (isMobileView)
        return <Tab.Pane>
            {AssistantPastors.map(assistantPastor =>
                <Segment key={assistantPastor.email}>
                    <Item.Group>
                        <Item>
                            <Item.Image
                                size="small"
                                circular
                                src={assistantPastor.picture}
                                style={{ marginBottom: 3 }}
                            />
                            <Item.Content>
                                <Item.Header>
                                    {assistantPastor.title}
                                </Item.Header>
                                <Item.Description>
                                    {assistantPastor.email} <br />
                                    {assistantPastor.phone}<br />
                                </Item.Description>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Segment>)
            }
        </Tab.Pane>

    return (
        <Tab.Pane>
            <Card.Group>
                {AssistantPastors.map(assistantPastor =>
                    <PastorCardItem key={assistantPastor.email} title={assistantPastor.title} email={assistantPastor.email}
                        picture={assistantPastor.picture} phone={assistantPastor.phone} />)
                }
            </Card.Group>
        </Tab.Pane>
    );
};

export default AssistantPastorsTab;