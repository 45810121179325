import React from 'react';
import { Tab, Segment, Item } from 'semantic-ui-react';

const SeniorPastorTab = () => {

    return (
        <Tab.Pane>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Image
                            size="small"
                            circular
                            src={'/assets/images/pastors/Jkh.png'}
                            style={{ marginBottom: 3 }}
                        />
                        <Item.Content>
                            <Item.Header>
                                진 기 현(REV. KI HYUN JIN)
                            </Item.Header>
                            <Item.Description>
                                0438-820-691 <br />
                                8810-4416<br />
                                jinkihyun@hanmail.net<br /><br />

                                장로회 신학대학교 신학과 (B.Th)<br />
                                장로회 신학대학교 신학대학원 (M.Div)<br />
                                Azusa Pacific University (M.A R)<br />
                                Fuller Theological Seminary(D. Min in Intercultural Studies)<br />
                                주안대학원 대학교(Ph.D)<br />
                                서울 염산교회 지도교사 및 교육전도사(1985-1989)<br />
                                인천 주안장로교회 전임 교역자 및 부목사(1989-1996)<br />
                                시드니 광림교회 담임(1996-1998)<br />
                                시드니 주안교회 담임(1998. 11-현재)<br />
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
        </Tab.Pane>
    );
};

export default SeniorPastorTab;
